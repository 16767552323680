import { Component, OnDestroy, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { NbDialogService } from '@nebular/theme';
import {cloneDeep} from 'lodash'


import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter, catchError, debounceTime, delay, tap, switchMap } from 'rxjs/operators';
import { Observable, throwError, Subject, forkJoin, fromEvent, BehaviorSubject, combineLatest, from } from 'rxjs';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../auth.service';
import { AccountsService } from '../../../services/accounts.service';

import { analyticsMapping } from './analytics-mapping';
import { AnalyticsService } from '../../../services/analytics.service';
import { PagesService } from '../../../services/pages.service';
import {RenderingService} from '../../../services/rendering.service';
import { HelpCenterService } from '../../../pages/help-center/help.service';


import { CreateUserDialogComponent } from '../../../components/create-user-dialog/create-user-dialog.component';
import { AreYouSureDialogComponent } from '../../../components/are-you-sure-dialog/are-you-sure-dialog.component';

import { TrialHasEndedDialogComponent } from '../../../components/trial-has-ended-dialog/trial-has-ended-dialog.component';
import { PlanHasBeenCancelledDialogComponent } from '../../../components/plan-has-been-cancelled-dialog/plan-has-been-cancelled-dialog.component';

import { MediaLibraryDialogComponent } from '../../../components/media-library-dialog/media-library-dialog.component';
import { MobileMessageDialog } from '../../../components/mobile-message-dialog/mobile-message-dialog.component';
import { AdminCustomizationDialogComponent } from '../../../components/admin-customization-dialog/admin-customization-dialog.component';
import { AccountCustomizationDialogComponent } from '../../../components/account-customization-dialog/account-customization-dialog.component';
import { AccountComponent } from '../../../pages/account/account.component';
import { ToasterService } from '../../../services/toaster.service';
import {EditorV4Service} from '../../../services/editorv4.service';
import {ReactDataService} from '../../../services/reactData.service';
import { SenderDataService } from '../../../pages/account/account.service';
import { CheckPaymentStatusDialogComponent } from '../../../components/check-payment-status-dialog/check-payment-status-dialog.component';
import { ReactFacade } from '../../../facades/react.facade';


import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { SubscriptionFacade } from '../../../pages/profile/subscription/subscription.facade';
import { CookiesService } from '../../../services/cookies.service';
import { UsersService } from '../../../services/usersV2.service';
import {SubscriptionDialogFacade} from "../../../pages/profile/subscription/subscription-dialog.facade";
import {MediaService} from "../../../services/media.service";
import { CropperComponent } from '../../../components/cropper/cropper.component';
import { AdminDataPublishDialogComponent } from '../../../components/admin-data-publish-dialog/admin-data-publish-dialog.component';
import { AccountIsOnHoldDialogComponent } from '../../../components/account-is-on-hold-dialog/account-is-on-hold-dialog.component';

import {BulkSendDialogComponent} from "../../../components/bulk-send-dialog/bulk-send-dialog.component";
import {
  InviteYourTeamDialogComponent
} from "../../../pages/share-flow/invite-your-team-dialog/invite-your-team-dialog.component";
import { DiscardDialog } from '../../../components/discard-dialog/discard-dialog.component';
import { DeleteAccountDialog } from '../../../components/delete-account-dialog/delete-account-dialog.component';
import { CancelSubscriptionDialog } from '../../../pages/profile/cancel-subscription-dialog/cancel-subscription-dialog.component';
import { UpgradePlanDialog } from '../../../pages/profile/subscription/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { SuccessUpdatePlanDialog } from '../../../pages/profile/subscription/success-update-plan-dialog/success-update-plan-dialog.component';

declare const Paddle;
declare const hj: any;
const { detect } = require('detect-browser');
const browser = detect();

declare global {
  interface Window {
    $editorObservables: Record<string, any>;
    $globalObservables: Record<string, any>;
    clarity: any;
  }
}

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  clickSubscription;
  userPictureOnly: boolean = false;
  userInfo: any;
  admin = false;
  administrator = false;
  adminCore = false;
  userType = 'user';
  orgId;
  orgInfo;
  viewId = null;
  analyticsLink;
  accountsLoading = true;
  daysForTrialEnd = 0;
  daysForCancelAt = 0;
  showTrialEndBar = false;
  showTrialEndBarClosed = false;

  trialEndedDialogOpen = false;
  planHasBeenCancelledDialogOpen = false
  accountIsOnHoldDialogOpen = false;

  showBrowserSupportMessage = false;
  mobileLoader = false;
  hideMenu = false;
  isOffline = false;
  showRequestTeamMessage = false;
  sendingRequest = false;
  isEditorV4User = false;
  isEditPage = false;
  isShowPublishButton = false;
  isUpdateVersionButtonVisible = false;
  isVersionPublishing = false;
  isPreviewing = false;
  isPublishing$ = this.editorV4Service.isPublish$;
  isPublishTriggered = false;
  story = null;
  version = null;
  activeVersions =  []
  lastSaved = null;
  saveTimeout = null;
  isSaving: boolean = false;
  showLastSaved: boolean = true;
  showLastSavedTimeout$ = new Subject<boolean>();
  isEditorHeader$ = new BehaviorSubject<boolean>(false);
  accountCustomization$ = this.accountsService.accountCustomization$;
  isOrgAdmin: boolean;
  totalLiveStoriesCount: number = 1;
  liveStoriesLimitCount: number;
  getStartedPreviewImage = '/assets/images/getting-start.jpg';
  isTemplateSaving$ = new BehaviorSubject<boolean>(false);
  isPro: boolean = false;
  upgradeToProTitle: string = 'Upgrading to the <strong>Pro plan</strong>';
  isCouponApplied$ = this.cookiesService.isCouponApplied$;
  currentTheme = 'default';

  isAutoSaveOn = true;
  isHistoryOpen = false;
  isMenuVisible$ = new BehaviorSubject<boolean>(false);
  isStarter: boolean;
  isTeam: boolean;
  isStarterOrTrial: boolean;
  isRestoringHistoryVersion = false;
  selectedHistoryVersionUrl = null;
  lastSavedRefreshTimeout = null;
  lastSavedHumanizedText = 'saved';
  lockedMenuItems: string[] = ['Variables'];
  isNavigator = false;
  showUpdateBillingToast: boolean = false;
  showUpdateBillingSuccessToast: boolean = false;
  actionButtonTooltip = false;

  adminRestoreVersionUrl: string = '';

  activePathName: string = window.location.pathname

  senderData$ = this.senderDataService.personalInfoData$.pipe(
    map(data => {
      const sender = data ? data.sender : null;
      if (!sender) return null;
      const { name, email, picture } = sender;
      const initials = name.split(' ').slice(0, 2).map((n) => n[ 0 ]).join('');
      return { name, email, picture, initials };
    })
  )

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              public analyticsService: AnalyticsService,
              private pagesService: PagesService,
              private breakpointService: NbMediaBreakpointsService,
              public router: Router,
              private dialogService: NbDialogService,
              private ref: ChangeDetectorRef,
              private accountsService: AccountsService,
              private senderDataService: SenderDataService,
              private toasterService: ToasterService,
              private location: Location,
              private editorV4Service: EditorV4Service,
              private cookiesService: CookiesService,
              private mediaService: MediaService,
              private reactDataService: ReactDataService,
              private subscriptionDialogFacade: SubscriptionDialogFacade,
              private subscriptionFacade: SubscriptionFacade,
              private helpCenterService: HelpCenterService,
              private usersService: UsersService,
              public auth: AuthService,
              private renderingService: RenderingService,
              private reactFacade: ReactFacade,
              private activatedRoute: ActivatedRoute,
              private _ngZone: NgZone) {

              }

  ngOnInit() {

    const isMobile = window.innerWidth < 1200;
    const isHeaderDisabled = this.router.url.indexOf('headerDisabled=true') > -1;
    if (isMobile && !isHeaderDisabled) {
      this.hideMenu = true;
      if (!this.isHideMobileDialog()) this.openMobileDialog();
    }
    if (isHeaderDisabled) {
      document.body.classList.add('--header-disabled');
    }
    this.accountsService.isEditorV4Org().subscribe(status => {this.isEditorV4User = status;});
    this.editorV4Service.editorV4Status$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.isEditorV4User = data;
      if (!this.isEditorLink) {
        this.isEditorV4User = false;
      }
      if(this.isEditorV4User) {
        this.addEditorV4Class();
        hj('event', 'EditorV4');
      }
    });
    this.editorV4Service.publishVersionSuccess$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isVersionPublishing = false
    });
    this.editorV4Service.version$.pipe(takeUntil(this.destroy$)).subscribe(version => {
      if (version) {
        const ver = {...version}
        this.updateSelectedRevisionHistoryItem(ver);

        this.isUpdateVersionButtonVisible = true;
        this.version = ver;
      } else {
        this.resetVersionState();
      }
    });
    this.setupPaddle();
    this.currentTheme = this.themeService.currentTheme;
    this.initClickListener();
    this.auth.getOrgId$().subscribe(orgId => this.orgId = orgId);
    this.auth.getNavigator$().subscribe(isNavigator => this.isNavigator = isNavigator);
    this.auth.getAdminCore$().subscribe(admin => {
      this.adminCore = (admin === true)
      if(typeof(hj) === 'function') hj('event', 'No admin core');
    })
    this.userType = this.auth.userType;
    forkJoin([
      this.accountsService.info(),
      this.auth.isAdministrator$()
    ])
    .subscribe(([orgInfo, isAdmin]) => {
      this.orgInfo = orgInfo;
      this.loadEditorV4Files();
      this.setTestingGroupClass();
      if(this.orgInfo.parameters && this.orgInfo.parameters.isEditorV4) this.isEditorV4User = true;
      this.manageEditorV4();
      if (this.orgInfo.customization) this.accountsService.updateAccountCustomization(this.orgInfo.customization);
      this.checkFreeTrialEnd(orgInfo);
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.checkFreeTrialEnd(orgInfo);
        }
      });
      if(typeof(hj) === 'function') {
        orgInfo.isSelfServ ? hj('event', 'Self Serve') : hj('event', 'B2B');
      }
      this.loadHubspotChat();
      this.isPro = this.accountsService.isPro;
      this.isStarterOrTrial = this.accountsService.isStarterOrTrial;
      this.isStarter = this.accountsService.isStarter;
      this.isTeam = this.accountsService.isTeam;
      this.reactDataService.userPlan$.next({
        isStarterOrTrial: this.isStarterOrTrial,
        isTrial: !this.accountsService.isPaid,
        isStarter: this.isStarter,
        isPro: this.isPro,
        isTeam: this.isTeam,
        isB2B: this.accountsService.isB2B && !this.accountsService.isAppSumo
      });
      if((!this.accountsService.isPaid || !!this.orgInfo.cancelAt) && !this.router.url.includes('/pages/wizard')) this.actionButtonTooltip = true;
    });
    this.auth.getAnalyticsLink$().subscribe(analyticsLink => {
      this.analyticsLink = analyticsLink ? analyticsLink : analyticsMapping[this.orgId];
    });
    this.auth.isAdmin$().subscribe(admin => {
      this.admin = admin === true;
      this.editorV4Service.isStorydocAdmin$.next(this.admin);
    })
    this.auth.isAdministrator$().subscribe(administrator => this.administrator = (administrator === true));
    this.isEditPage = this.isEditorLink;
    this.router.events.subscribe((val) => {
      this.isEditPage = this.isEditorLink;
    });
    this.auth.getUser$()
      .subscribe(user => {
        this.auth.updateCurrentUser(user);
        if(typeof(hj) === 'function') hj('event', user.sub);
      });
    this.usersService.getCurrentUser$().subscribe(user => this.userInfo = user);
    this.editorV4Service.showPublishButtonStatus$.pipe(takeUntil(this.destroy$)).subscribe(data => this.isShowPublishButton = !!data);

    this.editorV4Service.story$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      const { story, slides } = data;
      if (!this.version && story) {
        this.updateSelectedRevisionHistoryItem(story)
      }

      if (story) this.story = story;
      else if(slides) this.story = { ...this.story, slides };
      this.lastSaved = moment(this.story.updatedAt).format('DD/MM/YYYY, HH:mm');

      this.updateLastSavedText()
      if (this.lastSavedRefreshTimeout != null) clearInterval(this.lastSavedRefreshTimeout);
      this.lastSavedRefreshTimeout = setInterval(() => {
        this.updateLastSavedText()
      }, 30 * 1000);

      this.ref.detectChanges();
    });

    this.editorV4Service.versionUpdate$.pipe(takeUntil(this.destroy$)).subscribe(ver => {
      if (ver) {
        this.updateSelectedRevisionHistoryItem(ver)

        this.version = ver;
        this.lastSaved = moment(ver.updatedAt).format('DD/MM/YYYY, HH:mm');

        this.updateLastSavedText()
        if (this.lastSavedRefreshTimeout != null) clearInterval(this.lastSavedRefreshTimeout);
        this.lastSavedRefreshTimeout = setInterval(() => {
          this.updateLastSavedText()
        }, 30 * 1000);

        this.ref.detectChanges();
      }

    });

    this.getSenderData$().subscribe(([ info, user ]) => this.updateSenderData({ info, user }))

    this.editorV4Service.showToast$.pipe(takeUntil(this.destroy$)).subscribe(toast => {
      if (toast) {
        this.toasterService.showToast(toast.type, toast.title, toast.body, toast.config);
      }
    });


    this.editorV4Service.walkthrough$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (!data) return
      if (!['skip_tour', 'view_step'].includes(data.type)) return;
      const hasSkipped = data.type === 'skip_tour';

      const newWalkthrough = {
        hasSkipped: hasSkipped,
        lastStepIndex: data.stepIdx
      }

      this.usersService.updateWalkthrough(newWalkthrough).pipe(catchError(err => {
        this.track('failed to update walkthrough on user', {
          'failure message': err.message
        })
        return throwError(err)
      })).subscribe(res => {
        this.usersService.currentUser$.next({
          ...this.usersService.currentUser$.getValue(),
          walkthrough: newWalkthrough
        })
      });
    });

    this.showLastSavedTimeout$.pipe(takeUntil(this.destroy$), debounceTime(2000)).subscribe(() => {
      this.showLastSaved = true;
      this.ref.detectChanges();
    });
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.showBrowserSupportMessage = browser.name === 'firefox' || browser.name === 'safari';

    window.addEventListener("offline", (event) => {
      this.isOffline = true;
    });

    window.addEventListener("online", (event) => {
      this.isOffline = false;
    });

    this.getTotalLiveStoriesCount();
    this.screenResizeListener();
    this.urlChangeListener();
    this.checkURL();

    this.auth.getUserType$().pipe(takeUntil(this.destroy$)).subscribe(userType => {
      this.reactDataService.userRole$.next(userType);
    });
    fromEvent(document, 'settingsRenderAngular').pipe(takeUntil(this.destroy$)).subscribe((event: CustomEvent) => {
      const { action, variable, story } = event.detail;
      this.trackStory('success get event from react', {
        'event action': event.detail.action
      }, 'editorv4', story);
      if (action === 'selectMedia') this.selectMedia(variable);
    });
    fromEvent(document, 'navigateByUrl').pipe(takeUntil(this.destroy$)).subscribe((event: CustomEvent) => {
      if (event.detail.path) {
        return this.navigateByUrl(event.detail.path);
      }
    });
    fromEvent(document, 'firstVersionCreated').pipe(takeUntil(this.destroy$)).subscribe((event: CustomEvent) => {
      if (event.detail.version) {
        this.firstVersionCreation();
      }
    });
    fromEvent(document, 'openHelpCenter').pipe(takeUntil(this.destroy$)).subscribe((event: CustomEvent) => {
      if (event.detail.data) {
        this.openHelpCenter(event.detail.data, event.detail.page);
      } else {
        this.openHelpCenter({});
      }
    });
    fromEvent(document, 'openTeamDialog').pipe(takeUntil(this.destroy$)).subscribe((event: CustomEvent) => {
      this.subscriptionDialogFacade.upgradeToTeamDialog(event.detail.source);
    });
    fromEvent(document, 'openProDialog').pipe(takeUntil(this.destroy$)).subscribe((event: CustomEvent) => {
      this.subscriptionDialogFacade.upgradeToProDialog({source: event.detail.source})
      this.subscriptionFacade.proDialogAnalytics(event.detail.source);
    });
    fromEvent(document, 'openInviteYourTeamDialog').pipe(takeUntil(this.destroy$)).subscribe((event: CustomEvent) => {
      if (this.administrator) {
        this.dialogService.open(InviteYourTeamDialogComponent, {
          closeOnBackdropClick: false,
          closeOnEsc: false,
          context: {}
        });
      }
    });
    fromEvent(document, 'openBulkSendDialog').pipe(takeUntil(this.destroy$)).subscribe((event: CustomEvent) => {
      this.dialogService.open(BulkSendDialogComponent, {
        closeOnBackdropClick: false,
        closeOnEsc: false,
        context: {
          page: event.detail.page,
          screen: event.detail.screen,
          orgInfo: this.orgInfo,
          templateDownloaded: event.detail.templateDownloaded
        }
      });
    });

    fromEvent(document, 'updateOrgInfo').pipe(takeUntil(this.destroy$)).subscribe((event: CustomEvent) => {
      if (event.detail.account) {
        return this.accountsService.updateOrgInfo(event.detail.account);
      }
    });

    fromEvent(document, 'updatePlanPropertiesInfo').pipe(takeUntil(this.destroy$)).subscribe((event: CustomEvent) => {
      if (event.detail.trigger) {
        this.subscriptionFacade.updatePlanPropertiesInfo(
          event.detail.trigger,
          event.detail.value,
          event.detail.action,
          event.detail.payload
        );
      }
    });

    fromEvent(document, 'sd-track').pipe(takeUntil(this.destroy$)).subscribe((e: CustomEvent) => {
        const { event, params = {} } = e.detail;
        if(!event) return;
        this.analyticsService.track(event, params);
      })

    fromEvent(document, 'sd-track-story').pipe(takeUntil(this.destroy$)).subscribe((e: CustomEvent) => {
      const { event, page, params = {} } = e.detail;
      if (!event) return;
      const isPage = page && typeof page === 'object' && page._id;
      if (event && !isPage) return this.analyticsService.track(event, params);
      this.analyticsService.trackStory(event, page, {
        ...params
      });
    })

    fromEvent(document, 'sd-track-version').pipe(takeUntil(this.destroy$)).subscribe((e: CustomEvent) => {
      const { event, page, version, params = {} } = e.detail;
      if (!event) return;
      if (!version && page) return this.analyticsService.trackStory(event, page, params);
      if(!page || !version) return;
      this.analyticsService.trackVersion(event, page, version, {
        ...params
      });
    })

    fromEvent(document, 'sd-hubspot-event').pipe(takeUntil(this.destroy$)).subscribe((e: CustomEvent) => {
      const { event, payload = {} } = e.detail;
      this.analyticsService.sendHubspotInternalEvent(event, payload);
    })

    fromEvent(document, 'sd-hubspot-property').pipe(takeUntil(this.destroy$)).subscribe((e: CustomEvent) => {
      const { payload = {} } = e.detail;
      this.analyticsService.sendHubspotProperty(payload);
    })
    fromEvent(document, 'sd-navigate-versions-page').pipe(takeUntil(this.destroy$)).subscribe((e: CustomEvent) => {
       const { payload = {} } = e.detail;
      this.router.navigateByUrl(`pages/editor/${payload.id}/versions`)
    })

    fromEvent(document, 'sd-user-menu-action').pipe(takeUntil(this.destroy$)).subscribe((e: CustomEvent) => {
      const { action } = e.detail;
      this.onMenuItemClick(action);
    })

    fromEvent(document, 'open-cancel-subscription-dialog').pipe(takeUntil(this.destroy$)).subscribe((e: CustomEvent) => {
      this.dialogService.open(CancelSubscriptionDialog, {
        context: {
          cancelCheckout: this.cancelCheckout.bind(this)
        },
        closeOnBackdropClick: false
      }).onClose.subscribe(response => {
        if (!response) return;
        if (response.openPlansPage && !response.cancelCheckout) setTimeout(() => window.location.href = '/pages/profile/plans', 500);
        if (response.cancelCheckout) this.cancelCheckout({ openPlansPage: true }).subscribe();
      })
    })

    fromEvent(document, 'open-upgrade-to-annual-dialog').pipe(takeUntil(this.destroy$)).subscribe((e: CustomEvent) => {
      const { planKey = '' } = e.detail;

      if (this.orgInfo.cancelAt) return;
      this.dialogService.open(UpgradePlanDialog, {
        closeOnBackdropClick: false,
        closeOnEsc: false,
        context: {
          title: planKey === 'Starter' ? 'Starter' : 'Pro'
        }
      }).onClose
        .pipe(switchMap(res => {
          if (!res) return from([null]);
          return this.dialogService.open(SuccessUpdatePlanDialog, {
            closeOnBackdropClick: false,
            closeOnEsc: false,
            context: {}
          }).onClose
        })).subscribe()
    })

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const { subject, topic, help } = params;
      if(!help) return;
      if (subject && topic) this.openHelpCenter({ subject, topic });
      else this.openHelpCenter({});
    });

    this.editorV4Service.analyticsEvent$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (!data) return;
      if ((data.params.screen || '').includes('share-dialog')) {
        if (data.type === 'story') {
          const { page } = data;

          if (page && page.settings) {
            this.analyticsService.trackStory(data.event, page, data.params);
          }
        }

        if (data.type === 'version') {
          const { page, version } = data;
          const versionData = version || this.version;

          if (page && page._id && versionData && versionData.data && versionData.data.title) {
            this.analyticsService.trackVersion(data.event, page, versionData, data.params);
          }
        }
      }

      if (data.type === 'common') {
        this.analyticsService.track(data.event, data.params);
      }
      if (data.type === 'send-hubspot-internal-event') {
        this.analyticsService.sendHubspotInternalEvent(data.event, data.payload);
      }
      if (data.type === 'send-hubspot-property') {
        this.analyticsService.sendHubspotProperty(data.payload);
      }
    });



    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.activePathName = val.url
        this.ref.detectChanges();
      }
    })
  }

  cancelCheckout({ openPlansPage }: { openPlansPage: boolean }): Observable<boolean> {
    this.analyticsService.track('click cancel subscription');

    return this.accountsService.cancelPlan()
      .pipe(
        catchError((err: any): Observable<never> => {
          this.analyticsService.track('failed to cancel subscription');
          this.toasterService.showToast('danger', 'Error occurred canceling your subscription.');
          return throwError(err);
        }),
        map(account => {
          this.analyticsService.track('success canceling subscription');
          this.toasterService.showToast('success', `Subscription canceled successfully`);

          if (openPlansPage) {
            setTimeout(() => window.location.href = '/pages/profile/plans', 500);
          }

          return true;
        })
      );
  }

  openHelpCenter(data, page?: any) {
    if (page && page._id) {
      this.helpCenterService.setPage(page);
    }
    this.helpCenterService.setFormFieldsData(data);
    this.router.navigateByUrl("/pages/help");
  }

  firstVersionCreation() {
    const isCreatedAfterApril26 = (new Date(this.orgInfo.createdAt).getTime() > new Date('2022-04-26T00:00:00.000+00:00').getTime());
    if((this.orgInfo && this.orgInfo.firstVersionCreated) || !isCreatedAfterApril26) return
    this.accountsService.firstVersionCreation()
      .pipe(
        catchError((err: any) : Observable<any> => {
          this.trackStory('failed account data update', {
            'failure message': err.message
          });
          return throwError(err);
        })
      )
      .subscribe(info => {
        this.orgInfo = info;
        this.accountsService.updateOrgInfo(this.orgInfo);
        this.analyticsService.sendHubspotInternalEvent('first version created');
      })
  }

  selectMedia(variable) {
    if (!variable) return;
    this.dialogService.open(MediaLibraryDialogComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
      context: {
        variable: {
          ...variable,
          type: variable.type === 'image-video' ? 'image' : variable.type
        }
      }
    }).onClose.subscribe(item => {
      if(item) {
        this.openCropper(variable, item);
      } else {
        this.mediaService.updateMediaLibraryState(null);
      }
    });
  }

  openCropper(variable, item) {
    const url = item.url;
    if (variable.type === 'video' || (variable.type === 'image-video' && url.endsWith('.mp4')) || (item.type && ['image/gif', 'image/svg+xml'].includes(item.type))) {
      return this.setSourceValue(url, variable);
    } else {
      this.dialogService.open(CropperComponent, {
        context: {
          variable,
          url,
          admin: this.admin
        }
      })
        .onClose.subscribe(res => {
        if(!res) {
          this.selectMedia(null)
        } else if(res.url) {
          this.mediaService.updateMediaLibraryState(null);
          this.setSourceValue(res.url, variable);
        } else if(res.error) {
          // TODO: pass on react side
          // this.imageUploaderError = res.error;
        }
      });
    }
  }

  setSourceValue(croppedSrc: string, variable: any) {
    document.dispatchEvent(
      new CustomEvent('sd-share-dialog-media', {
        detail: {
          src: croppedSrc,
          variable
        },
      })
    );
    this._ngZone.run(() => {
      // pass croppedDataSrc to react
      // this.reactDataService.mediaLibrary$.next({ src: croppedSrc, variable });
      if (variable.isLiveLink) {
        this.reactDataService.liveLinkImageUrl$.next({ src: croppedSrc });
      } else {
        this.reactDataService.mediaLibrary$.next({ src: croppedSrc, variable });
      }
    })
  }

  ngAfterViewInit() {
    if (this.router.url.indexOf('headerDisabled=true') <= -1) return;
    const body = document.getElementsByTagName('body');
    if (body.length) body[0].classList.add('header-disabled');
  }

  loadEditorV4Files() {
    const isDev: boolean = false;
    const date: Date = new Date();
    let randomNumber: string = date.toISOString();
    let bundleJs: string, bundleCss: string;
    window.$editorObservables = this.editorV4Service;
    if (isDev) bundleJs = `http://localhost:3000/static/js/bundle.js?${randomNumber}`;
    else {
      bundleJs = `https://www.storydoc.com/assets/editor-bundle/dev/static/js/bundle.min.js?${randomNumber}`;
      bundleCss = `https://www.storydoc.com/assets/editor-bundle/dev/static/css/main.min.css?${randomNumber}`;
    }



    //bundleCss
    if(bundleCss) {
      let editorV4Style = document.createElement("link");
      editorV4Style.href = bundleCss;
      editorV4Style.type = 'text/css';
      editorV4Style.rel = 'stylesheet';
      document.head.append(editorV4Style);
    }

    //bundleJs
    let editorV4script = document.createElement("script");
    editorV4script.src = bundleJs;
    document.body.append(editorV4script);
    this.track('success loading react files', {'editorVersion':'V4'});
    this.initializeReactApp();
  }

  navigateByUrl (url: string) {
   return this.router.navigateByUrl(url);
  }

  initializeReactStore() {
    window.$globalObservables = this.reactDataService;
    document.dispatchEvent(new CustomEvent('initialize-react-app'));
  }

  initializeReactApp() {
    this.reactFacade.initializeApp$().subscribe(() => {
      this.initializeReactStore();
    });
  }

  manageEditorV4() {
    if (!this.adminCore && this.isWrongEnvironment()) return this.redirectToCorrectEnvironment()
    else if(this.adminCore && this.isWrongEnvironment()) this.wrongEnvAdminMessage();
    if(this.isEditorV4User) {
      if(this.isEditorLink) this.addEditorV4Class();
    }
  }

  isWrongEnvironment() {
    return (this.isEditorEnvironment() && !this.isEditorV4User) || (!this.isEditorEnvironment() && this.isEditorV4User);
  }

  isEditorEnvironment() {
    const hostnames = ['editor-staging', 'editor-dev', 'editor.storydoc'];
    return hostnames.some(hostname => window.location.hostname.includes(hostname));
  }

  redirectToCorrectEnvironment() {
    if(window.location.hostname === 'localhost') return;
    let redirectLink;
    redirectLink = this.isEditorV4User ? environment.editorV4Url : environment.editorUrl;
    this.analyticsService.track('success redirect to right editor environment', {
      'redirect-link': redirectLink
    })
    return window.location.href = redirectLink;
  }

  get isEditorLink() {
    return /\d/.test(this.router.url)
      && this.router.url.indexOf('editor/folder/') === -1
      && this.router.url.indexOf('/versions') <= -1
  }

  urlChangeListener() {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => this.checkURL());
  }

  checkURL() {
    if (this.router.url.indexOf('/pages/wizard') > -1) this.actionButtonTooltip = false;
    if(/^(?!.*(?:variables|versions|preview|folder|templates))(\/pages\/editor\/[a-zA-Z0-9]+(\/[a-zA-Z0-9]+)?(\?[^ ]+)?)$/.test(this.router.url)) {
      this.isEditorHeader$.next(true)
    } else this.isEditorHeader$.next(false)
  }

  addEditorV4Class() {
    this.isEditorHeader$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        document.body.classList.add('editorv4');
        document.querySelector('.scrollable-container').classList.add('editorV4');
      } else {
        document.body.classList.remove('editorv4');
      }
    })
  }

  isHistoryVersionSelected(ver, index) {
    if (!this.selectedHistoryVersionUrl) return index === 0;
    return ver.url === this.selectedHistoryVersionUrl;
  }


  get getRevisionHistory() {
    if (!this.story && !this.version) return []

    let history = []
    if (this.version && this.version.history) history = this.version.history;
    else if (this.story.history) history = this.story.history;

    const historyDates = [];
    for (let i = 0; i < Math.min(history.length, 20); i++) {
      const url = history[i]
      historyDates.push({ url, timestamp: url.split('metadata/').pop() });
    }

    return historyDates
  }

  get isRevisionHistoryEmpty() {
    return this.getRevisionHistory.length === 0
  }

  updateSelectedRevisionHistoryItem(obj) {
    if (!obj) return;
    const {history, contentPointer} = obj;

    if (!history) return;

    if (!this.story && !this.version){
      this.selectedHistoryVersionUrl = contentPointer;
      this.ref.detectChanges();
      return;
    }

    let oldHistory = []
    if (this.version) {
      if (this.version.history) oldHistory = this.version.history;
    } else if (this.story.history) oldHistory = this.story.history;

    if (oldHistory.length !== history.length) {
      this.selectedHistoryVersionUrl = null;
      this.ref.detectChanges();
      return;
    }

    for (let i = 0; i < Math.min(history.length, 20); i++) {
      if (oldHistory[i] !== history[i]) {
        this.selectedHistoryVersionUrl = null;
        this.ref.detectChanges();
        return;
      }
    }

    if (contentPointer) {
      this.selectedHistoryVersionUrl = contentPointer;
      this.ref.detectChanges();
    }
  }

  updateLastSavedText() {
    if (!this.lastSaved) this.lastSavedHumanizedText =  'saved';
    else this.lastSavedHumanizedText = moment(this.lastSaved, 'DD/MM/YYYY, HH:mm').fromNow();
    this.ref.detectChanges();
  }

  revisionHistoryTrackBy(index, item) {
    return item.url;
  }

  restoreHistoryVersionByUrl() {
    const url = this.adminRestoreVersionUrl.trim()
    if (url.length === 0) return;
    this.restoreHistoryVersion({url: url})
  }

  restoreHistoryVersion(ver) {
    this.trackStory('click change history');

    this.isRestoringHistoryVersion = true;
    this.selectedHistoryVersionUrl = ver.url;

    if (this.version) this.version = { ...this.version, contentPointer: ver.url };
    else this.story = { ...this.story, contentPointer: ver.url };

    this.ref.detectChanges();
    return this.renderingService.getStoryMetadata$(this.version ? this.version : this.story)
    .pipe(catchError(err => {
      this.adminRestoreVersionUrl = '';
      this.isRestoringHistoryVersion = false;
      this.selectedHistoryVersionUrl = null;
      this.toasterService.showToast('danger', 'Failed to change history', 'Please try again');
      this.trackStory('failed change history', {
        'failure message': err.message
      });
      this.ref.detectChanges();
      return throwError(err);
    }))
    .subscribe((st) => {
      this.trackStory('success change history');
      this.adminRestoreVersionUrl = '';
      this.isRestoringHistoryVersion = false;

      const result = cloneDeep(st)
      const adjustResult = (src: any) => {
        result.contentPointer = ver.url;
        if(src.saveCount) result.saveCount = src.saveCount;
        if(src.history) result.history = src.history;
        if(src.folderId) result.folderId = src.folderId;
        if( src.status) result.status = src.status;
        if(src.published) result.published = src.published;
        if(src.pending) result.pending = src.pending
      }

      if (this.version) adjustResult(this.version)
      else adjustResult(this.story)
      this.editorV4Service.story$.next({ story: result, type: 'history' });

      this.ref.detectChanges();
    });
  }

  updateSenderData(data) {
    if (!data) this.senderDataService.updatePersonalInfoData(undefined);
    const { info, user } = data;
    const customization = info.customization;
    const { profile, email, firstName } = user;
    const { picture = '', calendar = '' } = { ...profile };
    const sender = { name: firstName, email, picture, calendar };
    const org = { title: info.title, website: info.website || '', logo: customization && customization.logo ? customization.logo : '' }
    this.senderDataService.updatePersonalInfoData({org, sender})
  }

  getSenderData$() {
    return combineLatest([
      this.accountsService.getOrgInfo$(),
      this.usersService.getCurrentUser$()
    ])
    .pipe(
      catchError(err => {
        this.updateSenderData(undefined)
        return throwError(err)
      }),
      takeUntil(this.destroy$)
    )
  }

  openMobileDialog() {
    let totalStories = 0;
    this.mobileLoader = true;
    this.pagesService.count()
    .pipe(
      catchError((err: any) : Observable<any> => {
        this.mobileLoader = false;
        return throwError(err);
      })
    )
    .subscribe(res => {
      totalStories = +res.count || 0;
      if(totalStories > 0) {
        this.mobileLoader = false;

        const u = new URL(window.location.href)
        u.pathname = '/pages/analytics'
        return window.location.replace(u.toString())
      }
      this.mobileLoader = false;
      if (!document.querySelector('#small-screen-dialog')) {
        this.dialogService.open(MobileMessageDialog, {
          closeOnEsc: false,
          closeOnBackdropClick: false,
        })
      }
      this.analyticsService.track('view wizard on mobile device notification', {
        'screen': 'stories library'
      });
    });
  }

  setupPaddle() {
    if(environment.env !== 'production') Paddle.Environment.set('sandbox');
    Paddle.Setup({
      vendor: environment.paddle.vendorId,
      eventCallback: (data) => {
        this.subscriptionFacade.checkoutDetails$.next(data.eventData);
        if (data.event === "Checkout.PaymentMethodSelected") {
          this.analyticsService.track('click change payment method', {
            'screen': 'paddle-checkout-dialog',
            'payment method': data.eventData.payment_method
          });
        } else if(data.event === "Checkout.Loaded") {
          this.analyticsService.track('success payment dialog loaded', {
            'screen': 'paddle-checkout-dialog'
          });
        } else if (data.event === "Checkout.Error") {
          this.analyticsService.track('failed payment transaction', {
            'screen': 'paddle-checkout-dialog',
            'failure message': data.eventData.error
          });
        } else if(data.event === "Checkout.PaymentComplete") {
          this.analyticsService.track('success payment transaction', {
            'screen': 'paddle-checkout-dialog'
          });
        }
      }

    });
  }

  onUserMenuClick() {
    this.isMenuVisible$.next(!this.isMenuVisible$.value);
    this.ref.detectChanges();
  }

  closeMenu () {
    this.isMenuVisible$.next(false);
  }

  onMenuItemClick(action: string) {
    this.analyticsService.track('click user menu', {'item name': action});
    switch (action) {
      case 'profile': {
        this.analyticsService.track('click profile page', { placement: 'user menu', screen: 'user image' });
        this.dialogService.open(AccountComponent, { context: {} }).onClose.subscribe((status) => {
          if (!status) return;
          document.dispatchEvent(new CustomEvent('sd-invalidate-cache', { detail: { tags: [{ type: 'Users', id: 'CURRENT' }] } }));
        })
        break;
      };
      case 'invite-team': {
        this.router.navigateByUrl('/pages/profile/users');
        break;
      };
      case 'settings': {
        this.dialogService.open(AccountCustomizationDialogComponent, { context: {} })
        .onClose.subscribe(orgInfo => {
          if (!orgInfo) return;
          this.orgInfo = orgInfo;
        });
        break;
      };
      case 'variables': {
        this.router.navigateByUrl('/pages/editor/variables');
        this.track('click variables page', { placement: 'user menu', screen: 'user image' });
        break;
      };
      case 'media-library': {
        this.dialogService.open(MediaLibraryDialogComponent, {context: {}});
        break;
      };
      case 'admin-settings': {
        this.openAdminCustomizationDialog();
        break;
      };
      case 'restart-session': {
        this.auth.login();
        break;
      };
      case 'notion-deployment': {
        this.openAdminDataPublishDialogComponent();
        break;
      };
      case 'update-editor': {
        this.updateToV4Confirmation();
        break;
      };
      case 'delete-account': {
        this.deleteAccount();
        break;
      };
      case 'logout': {
        this.auth.logout();
        break;
      };
    }
    this.isMenuVisible$.next(false);
  }

  initClickListener() {
    this.clickSubscription = this.menuService.onItemClick()
    .pipe(
      filter((obj: any) => obj.tag === 'userMenu')
    )
    .subscribe((item: any) => {
      this.analyticsService.track('click user menu', {'item name': item.item.title});
      if(item.item.data.link){
        if(item.item.data.target) return window.open(item.item.data.link, item.item.data.target);
        this.router.navigateByUrl(item.item.data.link);
      } else if(item.item.data.media) {
        this.dialogService.open(MediaLibraryDialogComponent, {
          closeOnBackdropClick: false,
          closeOnEsc: false
        });
      } else if(item.item.data.refreshSession) {
        this.auth.login();
      } else if (item.item.data.adminCustomization) {
        this.openAdminCustomizationDialog();
      } else if(item.item.data.customize) {
        this.dialogService.open(AccountCustomizationDialogComponent, {
          closeOnBackdropClick: false,
          closeOnEsc: false,
          context: { }
        }).onClose.subscribe(orgInfo => {
          if (!orgInfo) return;
          this.orgInfo = orgInfo;
        });
      } else if(item.item.data.handleHelp) {
        this.handleHelpButton(null);
      } else if (item.item.data.account) {
        this.dialogService.open(AccountComponent, {context: { }})
      }
      else if (item.item.data.updateEditorV4) {
        this.updateToV4Confirmation();
      }
    });
  }

  updateEditorV4User() {
    this.accountsService.setEditorV4User(!this.isEditorV4User).subscribe(res => {
      this.isEditorV4User = res.parameters.isEditorV4;
      window.location.reload();
    });
  }

  checkFreeTrialEnd(orgInfo) {
    if (window.localStorage.getItem('updatedBilling') === 'true') {
      this.showUpdateBillingSuccessToast = true;
      this.ref.detectChanges();
    }

    this.orgInfo = orgInfo;
    if(orgInfo.analyticsViewId) this.viewId = orgInfo.analyticsViewId;
    if(orgInfo.plan === 'trial' && orgInfo.trialEnd) {

      const now = moment(new Date()); //todays date
      const end = moment(orgInfo.trialEnd); // another date
      const duration = moment.duration(end.diff(now));
      const days = duration.asDays();
      if (days <= 0) {
        if (this.trialEndedDialogOpen || ['/pages/profile/plans', '/pages/help'].includes(this.router.url)) return;
        this.trialEndedDialogOpen = true;
        this.analyticsService.sendHubspotProperty({ trial_status: 'expired' });
        this.analyticsService.sendHubspotInternalEvent('trial status expired');
         this.dialogService.open(TrialHasEndedDialogComponent, {
          ...this.dialogClosingParams,
          context: {}
        }).onClose.subscribe(() => {
          this.trialEndedDialogOpen = false;
        })

        this.showTrialEndBar = false;
        this.daysForTrialEnd = 0;
        if(!this.orgInfo.postTrialVisit) this.postTrialVisitDate();
      } else {
        this.daysForTrialEnd = parseInt(days.toString()) + 1;
        this.showTrialEndBar = false;
      }
    } else if(this.orgInfo.plan === 'paid') {
      if (this.orgInfo.cancelAt) {
        const now = moment(new Date());
        const end = moment(this.orgInfo.cancelAt);
        const duration = moment.duration(end.diff(now));
        const days = duration.asDays();
        this.daysForCancelAt = parseInt(days.toString()) + 1;

        if (this.planHasBeenCancelledDialogOpen || this.router.url === '/pages/profile/plans') return;
        const isExpired = end.diff(now) < 0;
        if(!isExpired || this.router.url.indexOf('/help') > -1) return;

        this.planHasBeenCancelledDialogOpen = true;
        this.areYouSureSubscriptionDialog();
      } else if (this.orgInfo.subscriptionStatus === 'past_due') {
        this.showUpdateBillingToast = true;
        this.ref.detectChanges();
      } else if (this.orgInfo.subscriptionStatus === 'paused') {
        if (this.accountIsOnHoldDialogOpen || this.router.url === '/pages/profile/plans') return;
        if(this.router.url.indexOf('/help') > -1) return;

        this.accountIsOnHoldDialogOpen = true;
        this.dialogService.open(AccountIsOnHoldDialogComponent, {
          ...this.dialogClosingParams,
          context: {
            orgInfo: this.orgInfo
          }
        }).onClose.subscribe(() => {
          this.accountIsOnHoldDialogOpen = false;
        })
      }
    }
  }

  postTrialVisitDate() {
    const isCreatedAfterApril26 = (new Date(this.orgInfo.createdAt).getTime() > new Date('2022-04-26T00:00:00.000+00:00').getTime());
    if (!isCreatedAfterApril26) return;
    this.accountsService.postTrialVisitDate()
    .pipe(
      catchError((err: any) : Observable<any> => {
        return throwError(err);
      })
    )
    .subscribe(info => {
      this.orgInfo = info;
      this.accountsService.updateOrgInfo(this.orgInfo);
      const date = moment(new Date()).utc().startOf('day');
      this.analyticsService.sendHubspotInternalEvent('post trial visit', { date: moment().format('DD/MM/YYYY hh:mm') });
      this.analyticsService.sendHubspotProperty({ post_trial_visit_date: date.valueOf() });
    })
  }

  areYouSureSubscriptionDialog() {
    this.dialogService.open(PlanHasBeenCancelledDialogComponent, {
      ...this.dialogClosingParams,
      context: {}
    }).onClose.subscribe(() => {
      this.planHasBeenCancelledDialogOpen = false;
    })
  }

  subscriptionExpiredFoundContact() {
    const data = {
      topic: 'Billing and plans',
      subject: 'Re-activate my account'
    }
    this.helpCenterService.setFormFieldsData(data)
    this.router.navigateByUrl('/pages/help');
  }

  openCheckout(selectedPlan) {
    if(!selectedPlan) console.error('plan not found');
    const subscriptionAnalyticsObject = {
      'subscription title': selectedPlan.title,
      'subscription period': selectedPlan.period,
      'subscription price': selectedPlan.price
    };
    this.analyticsService.track('click select subscription', subscriptionAnalyticsObject);
    const quantity = this.subscriptionFacade.getOrgSeats() + 1;
    const paddleOptions = {
      product: selectedPlan.id,
      email: this.userInfo.email,
      allowQuantity: false,
      quantity,
      disableLogout: (environment.env === 'production') ? true : false,
      closeCallback: (data) => {
        if(data.checkout.completed) {
          this.analyticsService.track('payment dialog success', subscriptionAnalyticsObject);
          this.dialogService.open(CheckPaymentStatusDialogComponent, {
            closeOnBackdropClick: false,
            closeOnEsc: false,
            context: {
              subscriptionAnalyticsObject,
              currentSubscriptionId: this.orgInfo.subscriptionId
            }
          });
          this.cookiesService.deleteCookie('sd-paddle-coupon', "/", "storydoc.com");
        } else {
          this.analyticsService.track('payment dialog closed', subscriptionAnalyticsObject);
          this.areYouSureSubscriptionDialog();
        }
      }
    }
    const coupon = this.getCouponCode();
    if (coupon) paddleOptions[ 'coupon' ] = coupon;
    Paddle.Checkout.open(paddleOptions);
  }

  getCouponCode() {
    const code = this.cookiesService.getCookie('sd-paddle-coupon');
    return code ? code : null;
  }

  checkCanceledSubscription() {
    if(!this.orgInfo || !this.orgInfo.cancelAt) return

  }

  get dialogClosingParams() {
    return this.adminCore ? {} : {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    }
  }

  createUser() {
    this.dialogService.open(CreateUserDialogComponent, {
      context: {
        orgInfo: this.orgInfo
      }
    })

  }

  triggerTemplateAutosave() {
    this.isTemplateSaving$.next(true);
    this.editorV4Service.autoSaveToggle$.next({ status: true, force: true });
  }

  ngOnDestroy() {
    if (this.lastSavedRefreshTimeout != null) {
      clearInterval(this.lastSavedRefreshTimeout)
    }

    this.destroy$.next();
    this.destroy$.complete();
    if(this.clickSubscription) this.clickSubscription.unsubscribe();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.router.navigateByUrl('/pages/home');
    // this.menuService.navigateHome();
    return false;
  }

  scheduleMeeting() {
    window.open('https://calendly.com/yaacovg/onboarding', '_blank');
  }

  openAnalytics() {
    this.analyticsService.track('click analytics');
    window.open(this.analyticsLink, '_blank');
  }

  changeOrgId(id: string) {
    this.auth.setOrgId(id);
    this.updateURLState();
    this.updateSearchParams();
    window.location.reload();
  }

  updateSearchParams() {
    const COOKIE_KEY = "sd-stories-filters";
    const current = localStorage.getItem(COOKIE_KEY);
    if (!current) return;
    const currentFilters = JSON.parse(current);
    delete currentFilters['folder'];
    localStorage.setItem(COOKIE_KEY, JSON.stringify(currentFilters));
  }

  updateURLState() {
    if(!this.location.path().includes('folder')) return
    this.location.replaceState('/pages/editor');
  }

  changeUserType(value) { // src/app/auth.service.ts
    this.auth.userType = value;
    window.location.reload();
  }

  clickFloatingSubscribeNow() {
    this.analyticsService.track('click subscribe now floating cta');
    this.subscribeNow();
  }

  clickHeaderSubscribeNow() {
    this.analyticsService.track('click subscribe now header');
    this.subscribeNow();
  }

  get ignoreSubscribeNow() {
    return this.adminCore && this.admin;
  }

  subscribeNow() {
    this.showTrialEndBar = false;
    this.router.navigateByUrl('/pages/profile/plans');
  }

  openAdminCustomizationDialog() {
    document.dispatchEvent(new CustomEvent('sd-trigger-dialog', {
      detail: {
        id: 'uZknTZTeNMV3lZSLhBSKR'
      }
    }));
  }

  openAdminDataPublishDialogComponent() {
    this.dialogService.open(AdminDataPublishDialogComponent, {
      context: {}
    })
  }

  openSubscriptionDetailsDialog() {
    if(!this.adminCore) return;
    const plan = this.plan;
    this.dialogService.open(AreYouSureDialogComponent, {
      context: {
        title: 'Your subscription details',
        body: `
          Plan: ${plan.title}<br />
          Period: ${plan.period}<br />
          Price: ${this.orgInfo.subscriptionPrice}<br />
          Plan id: ${this.orgInfo.subscriptionPlanId}<br />
          Subscription id: ${this.orgInfo.subscriptionId}<br />
          Last Payment: ${new Date(this.orgInfo.lastSuccessfulPayment).toLocaleDateString()}<br />
          Next Payment: ${new Date(this.orgInfo.nextPayment).toLocaleDateString()}<br />
        `,
        buttons: [{
          title: 'Dismiss',
          value: true,
          status: 'basic'
        }]
      }
    })
  }

  loadHubspotChat() {
    const chat = document.getElementById('hubspot-messages-iframe-container');
    if (chat && (!this.accountsService.isB2B ||  this.accountsService.isAppSumo)) return chat.classList.add('hidden');
  }



  get subscriptionTitle() {
    if(!this.orgInfo) return "Loading..."
    if(this.orgInfo.plan === 'trial') {
      return 'Free trial'
    } else {
      const plan = environment.paddle.plans.find(plan => plan.id === this.orgInfo.subscriptionPlanId);
      if(!plan || !plan.title) return "No plan found";
      return `
      Active ${plan.title} subscription,
      next payment on ${new Date(this.orgInfo.nextPayment).toLocaleDateString("en-US")}
      `;
    }
  }

  get plan() {
    if(!this.orgInfo || !this.orgInfo.subscriptionPlanId) return null;
    return environment.paddle.plans.find(plan => plan.id === this.orgInfo.subscriptionPlanId);
  }

  get showSubscribeNow() {
    return this.orgInfo && this.orgInfo.plan === 'trial';
  }

  get showSettingsButton() {
    if(!this.orgInfo) return false;
    return this.administrator && this.orgInfo; //  b2b & b2c can see this link. && (this.orgInfo.isSelfServ !== true || this.adminCore);
  }

  get showSubscriptionBadge() {
    return this.orgInfo && this.orgInfo.plan && this.orgInfo.subscriptionPlanId;
  }

  get isTestGroupA() {
    return this.accountsService.isAGroup;
  }

  handleHelpButton(trackMessage, trackPayload = {}) {
    if (trackMessage) this.track(trackMessage, trackPayload);
    this.analyticsService.sendHubspotInternalEvent('clicked help', { date:  moment().format('DD/MM/YYYY hh:mm')});
    this.analyticsService.sendHubspotProperty({ clicked_help_date:  moment(new Date()).utc().startOf('day').valueOf() });
    return this.router.navigateByUrl('/pages/help');
  }

  screenResizeListener() {
    if(this.isHideMobileDialog()) return;
    fromEvent(window, 'resize').pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((event: Event) => {
      if (window.innerWidth <= 1200 && !document.querySelector('#small-screen-dialog') && !this.isHideMobileDialog()) {
        this.dialogService.open(MobileMessageDialog, {
          closeOnEsc: false,
          closeOnBackdropClick: false
        });
        document.body.style.overflow = 'hidden';
      }
    });
  }
  isHideMobileDialog() {
    return [ '/pages/wizard', '/pages/analytics', '/pages/integrations/hubspot-iframe' ].includes(window.location.pathname);
  }

  get showRejoinButton() {
    if(!this.orgInfo) return false;
    const isCancel = !!this.orgInfo.cancelAt;
    return isCancel && moment(this.orgInfo.cancelAt).diff(moment(), 'days') < 30;
  }

  // ================== Editor V4 functions ==================

  get lastUpdatedStoryEmail() {
    const email = this.story && this.story.lastUpdateUserEmail ? this.story.lastUpdateUserEmail : '';
    if(this.adminCore) return email;
    const isStorydoc = email.includes('@storydoc.com');
    return isStorydoc ? 'Storydoc admin' : email;
  }

  changeToEditorV4() {
    this.addEditorV4Class();
    this.editorV4Service.convertToEditorV4$.next(true);
  }
  updateVersion() {
    this.trackStory('click update version button', {'editorVersion':'V4'});
    this.editorV4Service.updateVersionButton$.next(true);
    this.isVersionPublishing = true;
  }

  resetVersionState () {
    if (this.version) {
      this.selectedHistoryVersionUrl = null;
    }

    this.isVersionPublishing = false;
    this.isUpdateVersionButtonVisible = false;
    this.version = null;
  }


  goToStoriesLibrary() {
    this.editorV4Service.goBackStatus$.next(true);
  }
  clickStoryTitle() {
    this.trackStory('click story title');
    this.editorV4Service.isSettingsOpen$.next(true);
  }
  track(event, params = {}, screen = 'editorv4') {
    this.analyticsService.track(event, {
      'screen': screen,
      ...params
    });
  }
  trackStory(event, params = {}, screen = 'editorv4', storyFromReact = null) {
    let storyData = this.story;

    if (storyFromReact !== null) {
      storyData = storyFromReact;
    }

    if (this.version) {
      this.analyticsService.trackVersion(event, storyData, this.version, {
        'screen': screen,
        ...params
      });
    } else {
      this.analyticsService.trackStory(event, storyData, {
        'screen': screen,
        ...params
      });
    }
  }
  getStoryStatus() {
    if(!this.story || !this.story.settings || !this.story.status) return;
    return this.story.settings.isPdf ? 'PDF' : this.story.status;
  }

  getTotalLiveStoriesCount() {
    this.pagesService.count({status: 'Live'})
    .pipe(
      delay(5000),
      catchError((err: any) : Observable<any> => {
        return throwError(err);
      })
    ).subscribe(res => this.totalLiveStoriesCount = res.count === 0 ? 0 : 1);
  }

  setCookie(name: string, value: boolean, days: number) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  wrongEnvAdminMessage() {
    if (window.location.href.includes('localhost')) return;
    this.dialogService.open(DiscardDialog, {
      context: {
        body: '<strong>Storydoc admin information:</strong> <br><br> Wrong environment! <br> Do you want to open the correct one?',
        primaryText: 'Yes',
        secondaryText: 'No'
      }
    }).onClose.subscribe(value => value ? this.redirectToCorrectEnvironment() : null);
  }

  updateToV4Confirmation() {
    this.dialogService.open(DiscardDialog, {
      context: {
        body: `Are you sure you want to update this organization?<br><strong>(Current version is ${this.isEditorV4User ? 'V4' : 'V3'})</strong>`,
        primaryText: 'Yes',
        secondaryText: 'No'
      }
    }).onClose.subscribe(value => value ? this.updateEditorV4User() : null);
  }

  deleteAccount() {
    this.dialogService.open(DeleteAccountDialog, {
      context: {
        body: `Are you sure you want to delete this <br/> organization data?`,
        primaryText: 'Yes',
        secondaryText: 'No'
      }
    })
  }

  closeUpdateBillingToast() {
    this.showUpdateBillingToast = false;
    this.ref.detectChanges();
  }

  closeUpdateBillingSuccessToast() {
    this.showUpdateBillingSuccessToast = false;
    this.ref.detectChanges();
  }

  closeUpgradeButtonTooltip() {
    this.actionButtonTooltip = false;
  }

  setTestingGroupClass() {
    // disabled the AB test group
    const design = (this.isTestGroupA && false) ? '--sd-group-a' : '--sd-group-b';
    const root = document.documentElement;

    const proDesignSystem = {
      '--sd-group-a': {
        '--pro-main-color': '#FFB82E',
        '--pro-border-color': '#FFB82E',
        '--pro-background-light': '#fffaf1',
        '--pro-background-border-color': 'rgba(253, 185, 54, 0.2)',
        '--pro-background-dark': 'rgba(255, 184, 46, 0.3)',
        '--pro-text-color': '#12033e',
      },
      '--sd-group-b': {
        '--pro-main-color': '#FF7613',
        '--pro-border-color': '#D9620C',
        '--pro-background-light': '#fff1e7',
        '--pro-background-border-color': '#fff1e7',
        '--pro-background-dark': '#ffcca7',
        '--pro-text-color': '#FFFFFF',
      },
    };

    Object.entries(proDesignSystem[ design ])
      .forEach(([ key, value ]: [ string, string ]) => {
        root.style.setProperty(key, value);
      });
  }

  // ================== End of editor V4 functions ==================

}
