import { Component, HostListener, Input, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { Router } from "@angular/router";

import { AnalyticsService } from "../../../services/analytics.service";
import { AccountsService } from "../../../services/accounts.service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

enum CancelSubscriptionStep {
  Initial = 'Initial',
  ClaimCancellationDiscount = 'ClaimCancellationDiscount',
  BookMeeting = 'BookMeeting',
  Calendar = 'Calendar',
  CancellationCompleted = 'CancellationCompleted',
  SuccessfullyClaimedCancellationDiscount = 'SuccessfullyClaimedCancellationDiscount',
}

@Component({
  selector: "ngx-cancel-subscription-dialog",
  templateUrl: "cancel-subscription-dialog.component.html",
  styleUrls: ["cancel-subscription-dialog.component.scss"],
})
export class CancelSubscriptionDialog implements OnInit {
  contacts: object = {
    'CS': {
      name: 'Simon Burgaud',
      title: 'Customer relations',
      link: 'https://meetings.hubspot.com/simon-burgaud?embed=true',
      image: 'https://assets.storydoc.com/5d7d8922e00d9265/d959623f-6929-43b1-838c-791ba01adaf5'
    },
    'Design': {
      name: 'Jagoda',
      title: 'Lead designer',
      link: 'https://calendly.com/XXXXXX',
      image: 'https://XXX'
    },
  };
  reasonsMapping: object = {
    "A key feature is missing": {
      title: "Let's discuss your feature needs",
      message: 'We understand the importance of having all the necessary features. Before you make a final decision, could we explore potential solutions to bridge the feature gap? Your insights could help us prioritize development efforts to better serve your needs.',
      CTA: "Let's talk",
      contact: this.contacts['CS']
    },
    "Need co-editing and comments to work with my team": {
      title: "Let's enhance your team collaboration",
      message: "Before you decide to leave, could we discuss how we can improve collaboration within your team? Your input could help us tailor our platform to better meet your team's needs, ensuring smoother workflows and better outcomes for your projects",
      CTA: "Let's talk",
      contact: this.contacts['CS']
    },
    "Experienced technical issues": {
      title: "Let's try to fix your issues",
      message: "Before making a final decision, could we hop on a quick call to address any critical issues you've faced? Together, we might find a solution that works for you.",
      CTA: "Let's talk",
      contact: this.contacts['CS']
    },
    "Outcome wasn't quite what I hoped for": {
      title: "Let's work towards your desired outcome",
      message: "It seems the outcome fell short of your expectations. Before you make a decision, could we discuss how we can collaborate to ensure a more satisfactory outcome? Our design team is ready to assist you throughout the process, from refining your requirements to implementing solutions tailored to your goals",
      CTA: "Let's talk",
      contact: this.contacts['CS']
    },
    "Only needed it for a one-time use": {
      title: "Let's explore extended benefits",
      message: "We understand that your needs may change over time. Before making a decision, could we explore how our platform can offer additional benefits beyond your initial use case? Your insights could uncover new opportunities for leveraging our solution to add value to your endeavors.",
      CTA: "Let's talk",
      contact: this.contacts['CS']
    },
    "Love the product but it's too expensive": {
      title: "Let's find a pricing solution that fits",
      message: "We're thrilled to hear you love our product! Before you go, could we explore potential pricing adjustments or alternative plans that better align with your budget? Your satisfaction is our priority, and we're committed to finding a solution that allows you to continue benefiting from our platform without financial strain.",
      CTA: "Let's talk",
      contact: this.contacts['CS']
    },
    "Didn't feel it was worth the price": {
      title: "Let's maximize the value you get",
      message: "If you didn't feel our product was worth the price, we'd love to change your mind. Could we have a chat about your specific needs and expectations? Together, we can explore how to maximize the value you get from our platform. Your satisfaction is our priority, and we're committed to ensuring you find our product worthwhile.",
      CTA: "Let's talk",
      contact: this.contacts['CS']
    },
    "Found a better alternative for my needs": {
      title: "Let's find the best solution for you",
      message: "We understand the importance of finding the perfect solution that meets your needs. If you've found a better alternative, we'd like to understand what features or aspects made it stand out for you. Could we discuss your specific requirements? Our aim is to ensure our platform aligns closely with what you're looking for",
      CTA: "Let's talk",
      contact: this.contacts['CS']
    },
    "Wasn't really using it": {
      title: "Let's optimize your experience",
      message: "It seems like you weren't able to fully utilize our platform. We'd like to help change that. Could we explore how to optimize your experience? Whether it's through personalized tutorials, feature recommendations, or workflow suggestions, our goal is to ensure you get the most out of our product. Let's work together to make your experience more valuable and enjoyable.",
      CTA: "Let's talk",
      contact: this.contacts['CS']
    },
    "Other": {
      title: "Let's address your concerns and needs",
      message: "It seems there may be untapped potential in how you use our platform. Before you go, could we explore ways to optimize your usage and make sure you're getting the most out of our solution? Your insights could help us enhance your experience and prevent any underutilization.",
      CTA: "Let's talk",
      contact: this.contacts['CS']
    }
  };
  reasonsList: string[] = Object.keys(this.reasonsMapping);
  featuresList: string[] = [
    "Generating decks with AI",
    "Business-ready templates",
    "Booking 2x more meetings",
    "Standing out from the competition",
    "Reaching more customers"
  ];

  @Input() cancelCheckout: ({ openPlansPage }: { openPlansPage: boolean }) => Observable<boolean>;
  CancelSubscriptionStep = CancelSubscriptionStep;
  isLoading: boolean = false;
  currentStep = CancelSubscriptionStep.Initial;
  selectedReason: string | null = null;
  reasonData: object | null = null;
  reasonDescription: string = "";
  canCancel$ = new BehaviorSubject<boolean>(false);
  isCancellationDiscountApplicable: boolean = false;
  cancellationDiscountAmount: string;
  shouldReloadPage: boolean = false;

  constructor(
    protected ref: NbDialogRef<CancelSubscriptionDialog>,
    public analyticsService: AnalyticsService,
    public accountsService: AccountsService,
    public router: Router
  ) {}

  ngOnInit() {
    this.analyticsService.track("view cancel subscription form");
    this.getCancellationDiscountEligibility();
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    if (clickedElement.classList.contains('cdk-overlay-backdrop')) {
      this.onClose();
    }
  }

  onReasonChange(reason: string) {
    this.selectedReason = reason;
    this.reasonData = this.reasonsMapping[this.selectedReason];
    this.canCancel();
  }

  onConfirm({ displayCancellationCompletedStep } : { displayCancellationCompletedStep : boolean }) {
    if (!this.selectedReason) return;

    const trackPayload = {
      "cancel reason": this.selectedReason,
      "cancel reason description": this.reasonDescription,
    };

    this.analyticsService.track("click confirm cancellation", trackPayload);

    if (displayCancellationCompletedStep) {
      this.isLoading = true;
      this.cancelCheckout({ openPlansPage: false }).pipe(
        catchError((error) => {
          this.isLoading = false;
          this.ref.close({
            openPlansPage: true
          });
          return throwError(error);
        })
      ).subscribe((success) => {
        if (success) {
          this.currentStep = CancelSubscriptionStep.CancellationCompleted;
          this.shouldReloadPage = true;
        }
        this.isLoading = false;
      });
    } else {
      this.ref.close({
        cancelCheckout: true,
      });
    }
  }

  applyCancellationDiscount() {
    this.isLoading = true;
    this.analyticsService.track('click claim prevent discount');
    this.accountsService.applyCancellationDiscount()
      .pipe(
        catchError(err => {
          this.isLoading = false;
          return throwError(err);
        })
      )
      .subscribe((response: {
        status: string;
        cancellationDiscountAmount?: string;
        message?: string;
      }) => {
        if (response.status === "success") {
          this.cancellationDiscountAmount = response.cancellationDiscountAmount;
          this.currentStep = CancelSubscriptionStep.SuccessfullyClaimedCancellationDiscount;
          this.shouldReloadPage = true;
          this.isLoading = false;
        }
      });
  }

  reasonSelected() {
    let payload: { "prevent type": string };
    if (this.isCancellationDiscountApplicable) {
      this.currentStep = CancelSubscriptionStep.ClaimCancellationDiscount;
      payload = {
        "prevent type": "50% next payment"
      }
    } else {
      this.currentStep = CancelSubscriptionStep.BookMeeting;
      payload = {
        "prevent type": "book a meeting"
      }
    }
    this.analyticsService.track("view prevent cancel dialog", payload);
  }

  bookMeeting() {
    this.analyticsService.track("click book prevent cancel meeting");
    this.currentStep = CancelSubscriptionStep.Calendar;
  }

  onKeepPlan() {
    this.analyticsService.track("click keep plan");
    this.ref.close();
  }

  getCancellationDiscountEligibility() {
    this.isLoading = true;
    this.accountsService.getCancellationDiscountEligibility()
      .pipe(
        catchError(err => {
          this.isLoading = false;
          return throwError(err);
        })
      )
      .subscribe(response => {
        if (response.status === "success") {
          this.isCancellationDiscountApplicable = response.data.isCancellationDiscountApplicable;
          this.isLoading = false;
        }
      });
  }

  onContactUs() {
    this.analyticsService.track("click contact us from the cancel form");
    window.location.href = '/pages/help';
    this.ref.close();
  }

  onClose() {
    this.analyticsService.track("close cancel subscription form");
    if (this.shouldReloadPage) {
      this.ref.close({
        openPlansPage: true
      });
    } else {
      this.ref.close();
    }
  }

  textValueChange(value: string) {
    this.reasonDescription = value;
    this.canCancel();
  }

  canCancel() {
    const cancel = this.selectedReason &&
      (this.selectedReason !== "Other" || (this.selectedReason === "Other" && this.reasonDescription.length > 0));
    this.canCancel$.next(cancel);
  }
}
