import { Component, Input } from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { AnalyticsService } from "../../../../services/analytics.service";
import { AccountsService } from "../../../../services/accounts.service";
import { ToasterService } from "../../../../services/toaster.service";
import { Plan } from "../plan.interface";
import { catchError, delay, finalize, repeat, switchMap } from "rxjs/operators";
import { Observable, Subscription, from, throwError } from "rxjs";
import { environment } from "../../../../../environments/environment";
import * as moment from "moment";
import { SubscriptionFacade } from "../subscription.facade";
import { FailedUpgradePlanMessageComponent } from "../failed-upgrade-plan-message/failed-upgrade-plan-message.components";
import { SuccessUpdatePlanDialog } from "../success-update-plan-dialog/success-update-plan-dialog.component";

@Component({
  selector: "ngx-upgrade-plan-dialog",
  templateUrl: "./upgrade-plan-dialog.component.html",
  styleUrls: ["./upgrade-plan-dialog.component.scss"],
})
export class UpgradePlanDialog {
  constructor(
    protected ref: NbDialogRef<UpgradePlanDialog>,
    private analyticsService: AnalyticsService,
    private toasterService: ToasterService,
    private subscriptionFacade: SubscriptionFacade,
    private accountsService: AccountsService,
    private dialogService: NbDialogService,
  ) {}

  @Input() title: string = "Pro";
  @Input() upgradeToPro: boolean = false;
  @Input() period: string = "Annually";
  loading: boolean = false;
  failure: boolean = false;
  success: boolean = false;
  orgInfo: Record<string, any>;
  subscription: Subscription;
  plan: Plan;
  price: number;
  subscriptionAnalyticsObject: {'subscription title': string, 'subscription period': string, 'subscription price': number}

  track(event, params = {}) {
    this.analyticsService.track(event, {
      screen: "subscription-page",
      ...params,
    });
  }

  ngOnInit(): void {
    this.plan = this.subscriptionFacade.getSelectedPlan('Annually', this.title);
    this.accountsService.info().subscribe(res => this.orgInfo = res);
    this.subscriptionAnalyticsObject = {
        'subscription title': this.plan.title,
        'subscription period': this.plan.period,
        'subscription price': this.plan.price
    };
    this.getPrice();
  }

  upgrade() {
    this.loading = true;
    this.track("click update subscription", this.subscriptionAnalyticsObject);
    this.subscription = this.accountsService
      .updatePlan(this.plan.id)
      .pipe(
        catchError((err: any): Observable<any> => {
          const error = err.error;
          const isPaddleError = error && error.data === 'paddle';
          this.track("failed to update subscription", {
            "failure message": err.message,
            ...this.subscriptionAnalyticsObject
          });
          this.loading = false;
          this.close();
          this.failureMessageHandler(isPaddleError);
          return throwError(err);
        }),
        switchMap(() => this.checkPaymentStatus$())
      )
      .subscribe((orgInfo) => {
        if (
          orgInfo.subscriptionPlanId &&
          orgInfo.subscriptionPlanId === this.plan.id
        ) {
          const paddlePlan = environment.paddle.plans.find(
            (plan) => plan.id === orgInfo.subscriptionPlanId
          );
          this.handleSuccessPaymentAnalytics(paddlePlan, orgInfo);
          this.success = true;
          this.loading = false;
          this.clearSubscription();
          this.ref.close(true);
        } else if (
          orgInfo.lastPaymentErrorMessage && 
          orgInfo.lastPaymentErrorMessage !==
          this.orgInfo["lastPaymentErrorMessage"]
        ) {
          this.failed();
          this.track("failed paying customer", {
            "failure message": `lastPaymentErrorMessage: ${orgInfo.lastPaymentErrorMessage}`,
            ...this.subscriptionAnalyticsObject
          });
        }
      });
  }

  checkPaymentStatus$() {
    return this.accountsService.info(true).pipe(
      delay(2500),
      repeat(120),
      finalize(() => {
        if (!this.success) {
          this.failed();
          this.track("failed paying customer", {
            "failure message": "5 minutes passed with no update",
            ...this.subscriptionAnalyticsObject
          });
        }
      })
    );
  }

  clearSubscription() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  failed() {
    this.clearSubscription();
    this.toasterService.showToast(
      "danger",
      "Error occurred setting up your account, we will take care of that for you.",
      ""
    );
    this.failure = true;
    this.close();
  }

  getPrice() {
    const { planLimit, additionalSeats } = this.accountsService.getAccountSeats;
    this.price = (Number(planLimit) + Number(additionalSeats)) * this.plan.price;
  }

  handleSuccessPaymentAnalytics(paddlePlan: Plan, orgInfo: Record<string, any>) {
    const data = {};
    if (paddlePlan) {
      data["plan"] = paddlePlan.title;
      data["billingPeriod"] = paddlePlan.period;
      data["monthlyValue"] =
        paddlePlan.period === "Monthly"
          ? paddlePlan.price
          : paddlePlan.price / 12;

      this.analyticsService.sendHubspotProperty({
        total_payment_amount: paddlePlan.price,
        number_of_seats: paddlePlan.seats,
        plan_billing_type: paddlePlan.period,
        plan_monthly_value:
          paddlePlan.period === "Monthly"
            ? paddlePlan.price
            : paddlePlan.price / 12,
        plan_name: paddlePlan.title,
        trial_status: "inactive",
        success_paying_customer: moment(new Date())
          .utc()
          .startOf("day")
          .valueOf(),
      });
    }
    this.track("success upgrade customer", {
      'isAfterSuccessPaying': true,
      'upgrade subscription plan': true,
      'upgrade subscription plan type': `${this.title} monthly to ${this.title} annual`,
      ...this.subscriptionAnalyticsObject
    });
    if (orgInfo.parameters && orgInfo.parameters.gclid) data[ 'gclid' ] = orgInfo.parameters.gclid;
    this.analyticsService.sendHubspotInternalEvent(
      "success upgrade customer",
      data
    );
  }

  close() {
    this.ref.close();
  }


  failureMessageHandler(isPaddleError: boolean) {
    this.dialogService.open(FailedUpgradePlanMessageComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
      context: { isPaddleError }
    }).onClose.subscribe((reopen: boolean) => {
      if (!reopen) return;
      this.dialogService.open(UpgradePlanDialog, {
        closeOnBackdropClick: false,
        closeOnEsc: false,
        context: {
          title: this.title,
          upgradeToPro: this.upgradeToPro
        }
      }).onClose
      .pipe(switchMap(res => {
        if (!res) return from([ null ]);
        return this.dialogService.open(SuccessUpdatePlanDialog, {
          closeOnBackdropClick: false,
          closeOnEsc: false,
          context: {}
        }).onClose
      })).subscribe()
    })
  }

  ngOnDestroy() {
    this.clearSubscription();
  }
}
